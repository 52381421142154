import { Home, Dashboard, Services, Transactions, Deposit, Admin, Settings } from "../assets";
import { HomeWhite, DashboardWhite, ServicesWhite, TransactionsWhite, DepositWhite, AdminWhite, SettingsWhite } from "../assets";

export const navLinksMobileUser = [
    {
        id: 1, 
        title: "Home", 
        url: "/",
        logo: <Home />,
    },
    {
        id: 2, 
        title: "Dashboard", 
        url: "/dashboard",
        logo: <Dashboard />, 
    },
    {
        id: 3, 
        title: "Services", 
        url: "/services",
        logo: <Services />, 
    },
    {
        id: 4, 
        title: "Transactions", 
        url: "/transactions",
        logo: <Transactions />, 
    },
    {
        id: 5, 
        title: "Deposits", 
        url: "/deposit",
        logo: <Deposit />,
    },
    {
        id: 6, 
        title: "Admin", 
        url: "/admin",
        logo: <Admin />,
    },
    {
        id: 8, 
        title: "Settings", 
        url: "/settings",
        logo: <Settings />,
    },
]; 

export const navLinksMobileUserWhite = [
    {
        id: 1, 
        title: "Home", 
        url: "/",
        logo: <HomeWhite />,
    },
    {
        id: 2, 
        title: "Dashboard", 
        url: "/dashboard",
        logo: <DashboardWhite />, 
    },
    {
        id: 3, 
        title: "Services", 
        url: "/services",
        logo: <ServicesWhite />, 
    },
    {
        id: 4, 
        title: "Transactions", 
        url: "/transactions",
        logo: <TransactionsWhite />, 
    },
    {
        id: 5, 
        title: "Deposits", 
        url: "/deposit",
        logo: <DepositWhite />,
    },
    {
        id: 6, 
        title: "Admin", 
        url: "/admin",
        logo: <AdminWhite />,
    },
    {
        id: 8, 
        title: "Settings", 
        url: "/settings",
        logo: <SettingsWhite />,
    },
]; 

export const navLinks = [
    {
        id: 1, 
        title: "Services", 
        url: "/services",
    },
    {
        id: 2, 
        title: "Features", 
        url: "/features",
    },
    {
        id: 3, 
        title: "Partners", 
        url: "/partners",
    },
    {
        id: 4, 
        title: "Contact", 
        url: "/contact",
    },
    {
        id: 5, 
        title: "FAQ", 
        url: "/faq",
    },
]; 

export const navLinksUser = [
    {
        id: 1, 
        title: "Services", 
        url: "/services",
    },
    {
        id: 2, 
        title: "Features", 
        url: "/features",
    },
];